<script>
import { getShopList, issuedShopData, issuedShopProgress } from "api/issued";
import IssuedProgress from "@/pages/operationCenter/componnets/IssuedProgress/IssuedProgress.vue";

export default {
  name: "ShopIssued",
  components: { IssuedProgress },
  data: function() {
    return {
      setIntervalInstance: undefined,
      leftSearchInput: "",
      rightSearchInput: "",
      targetShopInfo: {},
      originShopInfo: {},
      requestStatusObj: { loading: false },
      table: {
        tableData: []
      },
      issuedProgressParams: {
        percentage: 0,
        active: 0,
        successNum: 0,
        failNum: 0
      }
    };
  },
  created() {
    console.log(this.$store);
    getShopList({
      params: {
        merCode: this.$store.getters.LoginInfo.merCode,
        // merchantNo: this.$store.getters.LoginInfo.merCode,
        merType: this.$store.getters.LoginInfo.merType
      }
    }).then(res => {
      this.table.tableData = res.data;
      console.log(res);
    });
  },
  methods: {
    selectTargetShop: function(data) {
      this.targetShopInfo = data;
    },
    selectOriginShop: function(data) {
      this.originShopInfo = data;
    },
    issued: function() {
      if (!this.originShopInfo.shopName) {
        this.$message.warning("请选择来源店铺");
        return;
      }
      if (!this.targetShopInfo.shopName) {
        this.$message.warning("请选择目标店铺");
        return;
      }
      if (this.targetShopInfo.shopNo === this.originShopInfo.shopNo) {
        this.$message.warning("来源店铺不能与目标店铺相同");
        return;
      }
      this.$refs.IssuedProgressRef.open();
      this.issuedProgressParams.active = 1;
      this.issuedProgressParams.successNum = 0;
      this.issuedProgressParams.failNum = 0;
      this.issuedProgressParams.percentage = 0;
      console.log("发送数据开始", new Date());
      issuedShopData({
        params: {
          "srcMerchantNo": this.originShopInfo.merchantNo,
          "srcShopNo": this.originShopInfo.shopNo,
          "srcShopName": this.originShopInfo.shopName,
          "targetMerchantNo": this.targetShopInfo.merchantNo,
          "targetShopNo": this.targetShopInfo.shopNo,
          "targetShopName": this.targetShopInfo.shopName
        }
      }).then(res => {
        this.issuedProgressParams.active = 2;
        this.setIntervalInstance = setInterval(() => {
          this.queryIssuedProgress(res.data);
        }, 1000);
      });
    },
    queryIssuedProgress(key) {
      issuedShopProgress(key).then(res => {
        // console.log(res.data);
        const data = res.data;
        this.issuedProgressParams.percentage = data.rate;
        this.issuedProgressParams.successNum = data.successCount;
        this.issuedProgressParams.failNum = data.errCount;
        if (data.rate === 100) {
          console.log("发送数据结束", new Date());
          this.issuedProgressParams.active = 3;
          clearInterval(this.setIntervalInstance);
        }
      });
    },
    issuedProgressClosed: function() {
      console.log("issuedProgressClosed");
      clearInterval(this.setIntervalInstance);
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="container_cards">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-input placeholder="请输入内容" v-model="leftSearchInput" class="input-with-select">
            <el-select :value="'店铺名称'" slot="prepend" placeholder="请选择">
              <el-option label="店铺名称" value="1"></el-option>
            </el-select>
          </el-input>
          <el-button class="clearfix_button" type="primary">搜索</el-button>
        </div>
        <div class="customElTableContainer">
          <el-table
            v-loading="requestStatusObj.loading"
            :data="table.tableData.filter(data => !leftSearchInput || data.shopName.toLowerCase().includes(leftSearchInput.toLowerCase()))"
            style="width: 100%"
            height="calc(100vh - 383px)"
          >
            <el-table-column type="index" label="序号" />
            <el-table-column align="center" prop="shopName" label="门店名称" />
            <el-table-column align="center" fixed="right" width="210" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  class="textBlue"
                  type="primary"
                  style="margin-right: 10px"
                  @click="selectOriginShop(row)"
                >
                  选中
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <div style="width: 10px;flex: none"></div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-input placeholder="请输入内容" v-model="rightSearchInput" class="input-with-select">
            <el-select :value="'店铺名称'" slot="prepend" placeholder="请选择">
              <el-option label="店铺名称" value="1"></el-option>
            </el-select>
          </el-input>
          <el-button class="clearfix_button" type="primary">搜索</el-button>
        </div>
        <div class="customElTableContainer">
          <el-table
            v-loading="requestStatusObj.loading"
            :data="table.tableData.filter(data => !rightSearchInput || data.shopName.toLowerCase().includes(rightSearchInput.toLowerCase()))"
            style="width: 100%"
            height="calc(100vh - 383px)"
          >
            <el-table-column type="index" label="序号" />
            <el-table-column align="center" prop="shopName" label="门店名称" />
            <el-table-column align="center" fixed="right" width="210" label="操作">
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  style="margin-right: 10px"
                  @click="selectTargetShop(row)"
                >
                  选中
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <div class="footer">
      <div class="footer_content">
        <p style="padding-bottom: 10px;color: blue; ">
          <span style="color: #000">注：</span>店铺下发数据包括设置数据、商品数据、店铺分类、单位、品牌
        </p>
        <span>数据来源店铺：
          <span style="color: red">
            {{
              originShopInfo.shopName ? originShopInfo.shopName : "请选择"
            }}
          </span>
        </span>
        <i class="el-icon-d-arrow-right"></i>
        <span>下发目标店铺：
          <span style="color: red">
            {{ targetShopInfo.shopName ? targetShopInfo.shopName : "请选择"
            }}
          </span>
        </span>
      </div>
      <div class="footer_buttons">
        <el-button type="primary" @click="issued">开始下发</el-button>
      </div>
    </div>
    <IssuedProgress
      ref="IssuedProgressRef"
      :percentage="issuedProgressParams.percentage"
      :active="issuedProgressParams.active"
      :fail-num="issuedProgressParams.failNum"
      :success-num="issuedProgressParams.successNum"
      @closed="issuedProgressClosed"
    >
    </IssuedProgress>
  </div>
</template>

<style scoped lang="less">
.container {

  &_cards {
    display: flex;
  }

  .box-card {
    height: calc(100vh - 271px);
    flex: 1;
  }

  .clearfix {
    display: flex;
    align-items: center;
  }

  .clearfix_button {
    margin-left: 12px;
  }

  .footer {
    background: #FFFFFF;
    margin-top: 10px;

    i {
      margin: 0 20px;
    }

    &_content {
      text-align: center;
      font-size: 16px;
      padding-top: 20px;
    }

    &_buttons {
      text-align: center;
      padding: 20px 0;
    }
  }
}

</style>


<style>
.el-select .el-input {
  width: 110px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input-with-select {
  width: 300px;
}

</style>
